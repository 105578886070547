import React from "react";

import { useLiff } from "react-liff";


const GameBanner = () => {
    const { liff } = useLiff();
    const lineId = liff.getDecodedIDToken().sub;
    return (
        <div class="additionalBanner inline-block rounded-[10px] mx-4 mt-[-5px] mb-10">
            <iframe src={`https://littlepet-fe.wedv.io/little-pet?userId=${lineId}`} style={{ width: '100%', height: '100%' ,position: 'fixed', top:'0', left:'0'}} >
            {/* <iframe src={`https://sourcecode.co.th/`} style={{ width: '100%', height: '100%' ,position: 'fixed'}}> */}
            </iframe>
        </div>
    );
};

export default GameBanner;