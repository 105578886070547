import React from "react";
import { useNavigate } from "react-router-dom";

import game from "../../assets/images/game-banner.png";
import { useLiff } from "react-liff";


const GameBanner = () => {
    const { liff } = useLiff();
    const lineId = liff.getDecodedIDToken().sub;

    const navigate = useNavigate();
    return (
        // <div className="additionalBanner bg-white  rounded-[10px] mx-4 mt-[-5px] mb-10 px-[16px]">
        //     <a
        //         href={`https://littlepet-fe.wedv.io/little-pet?userId=${lineId}`}
        //         target="_blank"
        //         rel="noopener noreferrer"
        //         >
        //         <img
        //             src={game} // URL รูปภาพที่ต้องการ
        //             alt="Game Banner"
        //             className="gameBanner"
        //         />
        //     </a>
        // </div>
        <div class="additionalBanner inline-block rounded-[10px] mx-4 mt-[-5px] mb-10">
            {/* <a onClick={()=>navigate('/game-little-pet')}> */}
            <a href={`https://liff.line.me/1656777670-OjqgXbER`}
                target="_blank" >
            <img src={game} alt="Game Banner" class="rounded-[10px]" />
            </a>
        </div>
    );
};

export default GameBanner;