import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import "swiper/css";
import "swiper/css/pagination";
import gift from "../../assets/images/icon/gift.svg";
import qrCode from "../../assets/images/icon/qr-code.svg";
import photo from "../../assets/images/icon/photo.svg";
import collectPoint1 from "../../assets/images/my-point/collect-point-1.svg";
import collectPoint2 from "../../assets/images/my-point/collect-point-2.svg";
import collectPoint3 from "../../assets/images/my-point/collect-point-3.svg";
import collectPointPhoto from "../../assets/images/my-point/collect-point-photo.svg";
import TopBanner from "../../components/layout/TopBanner";
import { SearchId } from "../../services/SearchId";
import { SearchPoint } from "../../services/MyPoint";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useLiff } from "react-liff";
import SecondaryButton from "../../components/button/SecondaryButton";
import MemberCard from "../../components/card/MemberCard";
import { useDispatch, useSelector } from "react-redux";
import { setMyPoint } from "../../features/MyPointSlice";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate, useLocation } from "react-router-dom";
import TopLogo from "../../components/layout/TopLogo";
import checkmark from "../../assets/images/checkmark.png";
import { SearchAdminConfig } from "../../services/Dropdown";
import { SearchQRPointHistories } from "../../services/MyPoint";
import InfiniteScroll from "react-infinite-scroller";
import { SearchPetOwnerProfile } from "../../services/Dogbook";
import tableReward from "../../assets/images/table-reward.jpg";
import helper from "../../functions/helper";
import GameBanner from "../game/gameBanner";

export default function MyPointMain() {
  const store = useSelector((state) => state.myPoint);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [member, setMember] = useState({});
  let [ownerProfile, setOwnerProfile] = useState(null);
  const [isPhoto, setIsPhoto] = useState(true);
  const [shopName, setShopName] = useState("-");

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    (async () => {
      try {
        getAdminConfig();
        const { memberId } = await getSearchId();
        console.log("memberId", memberId);
        if (!memberId) throw "memberId not found";
        const { member, historyPoint } = await getSearchPoint(memberId);
        getOwnerProfile(memberId);
        console.log("member >>", member);
        setMember(member);
        fnSetData("memberId", member.memberId);
        fnSetData("currentPoint", member.currentPoint);

        const shopName = await getScanData(memberId);
        setShopName(shopName);
      } catch (error) {
        console.log("fetchData error", error);
        navigate("/register");
      }
    })();
  }, []);

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        setOwnerProfile(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const urlPicture = reader.result.substring(
        reader.result.indexOf(",") + 1
      );
      console.log("urlPicture >>", `${urlPicture}`.substring(0, 15));
      fnSetData("selectedImage", urlPicture);
      navigate("/my-point-photo-upload");
    };
    reader.readAsDataURL(file);
  };

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setMyPoint(tmpObj));
  }

  async function getSearchId() {
    const { data } = await SearchId({ params: { lineId } });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getAdminConfig() {
    const { data } = await SearchAdminConfig({ params: { lineId } });
    if (+data["resultCode"] === 20000) {
      setIsPhoto(data.body.isRewardType == 1);
    }
  }

  async function getSearchPoint(memberId) {
    const { data } = await SearchPoint({ params: { memberId } });
    if (+data["resultCode"] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }
  async function getScanData(_memberId) {
    return new Promise((resolve, reject) => {
      SearchQRPointHistories({
        params: { memberId: _memberId, offset: 0, limit: 1 },
      })
        .then(async (res) => {
          if (res.data.resultCode === 20000) {
            console.log("res.data.body getScanData >>", res.data.body);
            const tmpShopName =
              res?.data?.body[0]?.event[0]?.clinicPetShop?.shopName ?? "-";
            resolve(tmpShopName);
          } else {
            resolve("-");
          }
        })
        .catch((error) => {
          resolve("-");
          console.log(error);
        });
    });
  }

  function collectStep(step) {
    const title = `ขั้นตอนที่ ${step}`;
    let image = null;
    let message = "";
    if (!isPhoto) {
      if (step === 1) {
        message = "ไปที่หน้าโรงพยาบาลสัตว์ คลินิก หรือเพ็ทช็อป";
        image = collectPoint1;
      } else if (step === 2) {
        message =
          "สแกน QR Code จากพนักงาน ที่โรงพยาบาล\nสัตว์ คลินิก หรือเพ็ทช็อป";
        image = collectPoint2;
      } else if (step === 3) {
        message = "ใช้คะแนนแลกรับสิทธิ์ประโยชน์มากมาย";
        image = collectPoint3;
      }
    } else {
      if (step === 1) {
        message = "ไปที่หน้าโรงพยาบาลสัตว์ คลินิก หรือเพ็ทช็อป";
        image = collectPoint1;
      } else if (step === 2) {
        message =
          "อัพโหลดรูปภาพที่ถ่ายภายในโรงพยาบาล\nสัตว์ คลินิก หรือเพ็ทช็อป";
        image = collectPointPhoto;
      } else if (step === 3) {
        message = "ใช้คะแนนแลกรับสิทธิ์ประโยชน์มากมาย";
        image = collectPoint3;
      }
    }
    return (
      <div className="flex items-center gap-3 my-2">
        <div className="flex-none">
          <img src={image} alt="image step" />
        </div>
        <div>
          <p className="text-gray text-[16px] whitespace-pre-line leading-5">
            {title}
          </p>
          <p className="text-darkGray text-[20px] whitespace-pre-line leading-5">
            {message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div id="my-point-page">
        <TopLogo />
        <TopBanner type="myPoint" />
        <div id="my-point" className="gradientBg mt-[-20px] px-[20px]">
          <MemberCard data={ownerProfile} showTierBar={true} />
          {/* <div className="py-[16px] text-white">
          <div className="text-[24px]">
            <span>ยินดีต้อนรับ </span>
            <span className="font-[600]">คุณ {member['firstName']} {member['lastName']}</span>
          </div>
          <div className="py-3">
            <hr className="opacity-50" />
          </div>
          <div className="flex justify-between text-[20px]">
            <div>NexCoin สะสมรวม</div>
            <div>
              <span className="text-[24px] font-[600]">{helper.numberWithCommas(member['currentPoint'])}</span>
              &nbsp;คะแนน
            </div>
          </div>
          <div className="mt-[-10px] mb-5">
            คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()}
          </div>
        </div> */}
          <div className="py-[16px] text-white">
            <div className="py-3">
              <hr className="opacity-50" />
            </div>
          </div>
        </div>
        <GameBanner />
        <div className="bg-white rounded-[10px] mx-4 mt-[-10px] mb-10 px-[16px]">
          <div id="myPointStampBanner">
            <div className="tInvite">ใหม่! กิจกรรมเช็คอินสะสม NexStamp</div>
            <button className="btn" onClick={() => navigate("/my-point-stamp")}>
              <i></i>
              <p>ดูสมุด NexStamp ของฉัน</p>
            </button>
          </div>
          <div className="py-[20px]">
            <p className="text-[24px] text-lightBlue">ประวัติการสะสมคะแนน</p>
            <div className="flex justify-between text-[20px]">
              <div className="text-[#8F8F8F]">สแกน QR Code:</div>
              <div className="text-darkGray">{shopName}</div>
            </div>
            <span
              className="text-[18px] text-[#0A4197]"
              onClick={() => navigate("/my-point-history-owner")}
            >
              ดูรายละเอียดประวัติการสะสม NexCoin <ChevronRightRoundedIcon />
            </span>
          </div>
          <SecondaryButton
            variant="outlined"
            className="w-full !border-[0] !bg-[#E1E8F2]"
            onClick={() => navigate("/redeem-gift-member")}
          >
            <img src={gift} alt="icon" className="mt-[-2px]" />
            ใช้ NexCoin แลกของรางวัลที่นี่
          </SecondaryButton>
          <div className="py-5 text-[24px] text-lightBlue">
            สะสม NexCoin ง่ายๆ ด้วยขั้นตอนต่อไปนี้
          </div>
          <div className="flex flex-col pb-5">
            {collectStep(1)}
            {collectStep(2)}
            {collectStep(3)}
          </div>
          <div className="pb-5">
            <img src={tableReward} alt="" />
          </div>
        </div>
        <div className="py-8"></div>
        <div className="flex flex-col justify-center bg-white px-[20px] pt-[16px] pb-[24px] fixed bottom-0 left-0 right-0">
          {!isPhoto ? (
            <PrimaryButton onClick={() => navigate("/my-point-scan")}>
              <img src={qrCode} alt="qr code" />
              <p className="text-[24px] text-white text-center">
                สแกนสะสม NexCoin และ NexStamp
              </p>
            </PrimaryButton>
          ) : (
            <PrimaryButton>
              <img src={photo} alt="photo" />
              <p className="text-[24px] text-white text-center">
                อัพโหลดรูปเพื่อสะสม NexCoin ที่นี่
              </p>
              <input
                className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                type="file"
                accept="image/*"
                onChange={(event) => handleImage(event)}
              />
            </PrimaryButton>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
